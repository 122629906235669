<template>
  <div>
    <section class="goal">
      <h2>非同期通信（axios）</h2>
      <div class="wrap">
        <p v-if="isLoading">ローディング中...</p>
        <p v-else-if="!heroTeam">データがありません</p>
        <ul v-else class="heroTeam">
          <li>チーム名 ...... {{ heroTeam.squadName }}</li>
          <li>活動範囲 ...... {{ heroTeam.homeTown }}</li>
          <li>結成 ...... {{ heroTeam.formed }}</li>
          <li>拠点 ...... {{ heroTeam.secretBase }}</li>
          <li>活動しているか ...... {{ heroTeam.active }}</li>
          <li>
            隊員 ......
            <ul>
              <li
                v-for="(member, memberIndex) in heroTeam.members"
                :key="memberIndex"
              >
                <ul>
                  <li>名前 ...... {{ member.name }}</li>
                  <li>年齢 ...... {{ member.age }}</li>
                  <li>正体 ...... {{ member.secretIdentity }}</li>
                  <li>
                    能力 ......
                    <ul>
                      <li
                        v-for="(power, powerIndex) in member.powers"
                        :key="powerIndex"
                      >
                        {{ power }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
    <hr class="border" />
    <p class="border_txt">これ以下は模写不要</p>
    <section class="question">
      <h2>問題</h2>
      <div class="wrap">
        <p className="question_txt">
          <a
            href="https://mdn.github.io/learning-area/javascript/oojs/json/superheroes.json"
            >https://mdn.github.io/learning-area/javascript/oojs/json/superheroes.json</a
          ><br />
          このAPIからデータを取得し上記のように表示させなさい<br />
          <br />
          【注意】<br />
          import axios from "axios";<br />
          npm install axios<br />
          が必要
        </p>
      </div>
    </section>
    <section class="answer">
      <h2>回答</h2>
      <div v-if="showAnswer">
        <button class="answer_btn" @click="showAnswer = false">
          回答を非表示
        </button>
        <div class="wrap">
          <pre>
            <code v-pre>
&lt;template&gt;
  &lt;p v-if="isLoading"&gt;ローディング中...&lt;/p&gt;
  &lt;p v-else-if="!heroTeam"&gt;データがありません&lt;/p&gt;
  &lt;ul v-else class="heroTeam"&gt;
    &lt;li&gt;チーム名 ...... {{ heroTeam.squadName }}&lt;/li&gt;
    &lt;li&gt;活動範囲 ...... {{ heroTeam.homeTown }}&lt;/li&gt;
    &lt;li&gt;結成 ...... {{ heroTeam.formed }}&lt;/li&gt;
    &lt;li&gt;拠点 ...... {{ heroTeam.secretBase }}&lt;/li&gt;
    &lt;li&gt;活動しているか ...... {{ heroTeam.active }}&lt;/li&gt;
    &lt;li&gt;
      隊員 ......
      &lt;ul&gt;
        &lt;li
          v-for="(member, memberIndex) in heroTeam.members"
          :key="memberIndex"
        &gt;
          &lt;ul&gt;
            &lt;li&gt;名前 ...... {{ member.name }}&lt;/li&gt;
            &lt;li&gt;年齢 ...... {{ member.age }}&lt;/li&gt;
            &lt;li&gt;正体 ...... {{ member.secretIdentity }}&lt;/li&gt;
            &lt;li&gt;
              能力 ......
              &lt;ul&gt;
                &lt;li
                  v-for="(power, powerIndex) in member.powers"
                  :key="powerIndex"
                &gt;
                  {{ power }}
                &lt;/li&gt;
              &lt;/ul&gt;
            &lt;/li&gt;
          &lt;/ul&gt;
        &lt;/li&gt;
      &lt;/ul&gt;
    &lt;/li&gt;
  &lt;/ul&gt;
&lt;/template&gt;

&lt;script&gt;
import axios from "axios";
export default {
  data() {
    return {
      isLoading: false,
      heroTeam: null,
    };
  },
  mounted() {
    this.isLoading = true;
    const requestConfig = {
      url: "https://mdn.github.io/learning-area/javascript/oojs/json/superheroes.json",
      method: "GET",
    };
    axios(requestConfig)
      .then((res) =&gt; {
        this.heroTeam = res.data;
      })
      .catch(() =&gt; {
        alert("通信に失敗しました");
      })
      .finally(() =&gt; {
        this.isLoading = false;
      });
  },
};
&lt;/script&gt;
&lt;style scoped&gt;
.heroTeam ul {
  padding: 0;
}
.heroTeam li {
  display: flex;
  list-style: none;
  margin: 0;
}
&lt;/style&gt;
            </code>
          </pre>
        </div>
      </div>
      <button v-else class="answer_btn" @click="showAnswer = true">
        回答を表示
      </button>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      showAnswer: false,
      isLoading: false,
      heroTeam: null,
    };
  },
  mounted() {
    this.isLoading = true;
    const requestConfig = {
      url: "https://mdn.github.io/learning-area/javascript/oojs/json/superheroes.json",
      method: "GET",
    };
    axios(requestConfig)
      .then((res) => {
        this.heroTeam = res.data;
      })
      .catch(() => {
        alert("通信に失敗しました");
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>
<style scoped>
.heroTeam ul {
  padding: 0;
}
.heroTeam li {
  display: flex;
  list-style: none;
  margin: 0;
}
</style>